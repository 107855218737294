<template>
  <div>
    <transition name="fade">
      <div class="contact-form" v-if="mailResult === 'input'" key="input">
        <form action="" method="post" ref="form" @submit.prevent>
          <div class="accordion-item  -is-active" data-name="Product">
            <div class="title  accordion-trigger" @click="toggleContent">
              <div class="row">
                <div class="col-1  col-sm-2  text-center  valign-middle">
                  <Icon class="-size-32  pos-relative">
                    <span class="_plus"></span>
                  </Icon>
                </div>
                <div class="col-10  col-sm-9  valign-middle">
                  <div class="h6  mb-0">
                    1. {{ $t('conversion.form.steps[0].title') }}*
                  </div>
                </div>
              </div>
            </div>
            <div class="accordion-content">
              <div class="content">
                <div class="row  mb-s">
                  <div class="col-4  col-offset-1  col-sm-10  col-xs-12  col-xs-offset-0">
                    <label for="sector">{{ $t('conversion.form.steps[0].select') }}*</label>
                    <div class="select-wrapper">
                      <select name="sector" v-model="products" tabindex="1">
                        <option v-for="sector in $t('conversion.profile_1.products.tabs')"
                                :key="sector.name"
                                :value="sector.product_keys">
                          {{ sector.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row  mb-s" v-if="products.length">
                  <div class="col-12  col-offset-1  col-xs-offset-0">
                    <label>{{ $t('conversion.form.steps[0].product') }}</label>
                  </div>
                  <div class="input-wrapper">
                    <transition-group name="fade">
                      <div class="col-2  col-sm-3  col-xs-6  col-xs-offset-0"
                           v-for="(productKey, index) in products"
                           :key="productKey"
                           :class="{
                              'col-offset-1': index === 0,
                              'col-sm-offset-1': (index % 3) === 0
                            }">
                        <div class="product-select">
                          <label :for="`product-${productKey}`">
                            <div class="product-image">
                              <img :src="productImage(productKey)">
                            </div>
                          </label>
                          <input type="radio" name="product"
                                 :id="`product-${productKey}`"
                                 :tabindex="(index + 1) + 1"
                                 :value="productKey"
                                 required>
                          <label :for="`product-${productKey}`" class="inline-label">
                            {{ $t(`products.${productKey}.name`) }}
                          </label>
                        </div>
                      </div>
                    </transition-group>
                  </div>
                </div>
                <div class="row">
                  <div class="col-11  col-offset-1  col-xs-12  col-xs-offset-0">
                    <Anchor @click.native="nextStep" class="font-weight-medium">
                      {{ $t('conversion.form.buttons.next') }}
                      <Icon class="-inline  -is-right">
                        <ArrowSVG></ArrowSVG>
                      </Icon>
                    </Anchor>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item" data-name="Dimensions">
            <div class="title  accordion-trigger" @click="toggleContent">
              <div class="row">
                <div class="col-1  col-sm-2  text-center  valign-middle">
                  <Icon class="-size-32  pos-relative">
                    <span class="_plus"></span>
                  </Icon>
                </div>
                <div class="col-10  col-sm-9  valign-middle">
                  <div class="h6  mb-0">
                    2. {{ $t('conversion.form.steps[1].title') }}*
                  </div>
                </div>
              </div>
            </div>
            <div class="accordion-content">
              <div class="content">
                <div class="row  mb-m">
                  <div class="col-3  col-sm-10  col-sm-offset-1  col-xs-12  col-xs-offset-0  mb-sm-xs"
                       v-for="(field, index) in $t('conversion.form.steps[1].fields')"
                       :key="field.label"
                       :class="{ 'col-offset-1': index === 0 }">
                    <div class="input-wrapper">
                      <label :for="field.name">{{ field.label }}*</label>
                      <input type="text"
                             :name="field.name"
                             required
                             :placeholder="field.placeholder"
                             @focus="tabActiveContent"
                             :tabindex="(index + 1) + 5">
                      <div class="error-msg">{{ field.error }}</div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-11  col-offset-1  col-xs-12  col-xs-offset-0">
                    <Anchor @click.native="nextStep" class="font-weight-medium">
                      {{ $t('conversion.form.buttons.next') }}
                      <Icon class="-inline  -is-right">
                        <ArrowSVG></ArrowSVG>
                      </Icon>
                    </Anchor>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item" data-name="Address">
            <div class="title  accordion-trigger" @click="toggleContent">
              <div class="row">
                <div class="col-1  col-sm-2  text-center  valign-middle">
                  <Icon class="-size-32  pos-relative">
                    <span class="_plus"></span>
                  </Icon>
                </div>
                <div class="col-10  col-sm-9  valign-middle">
                  <div class="h6  mb-0">
                    3. {{ $t('conversion.form.steps[2].title') }}*
                  </div>
                </div>
              </div>
            </div>
            <div class="accordion-content">
              <div class="content">
                <div class="row">
                  <div class="col-5  col-offset-1  col-sm-10  col-xs-12  col-xs-offset-0  mb-s">
                    <div class="input-wrapper">
                      <label :for="$t('conversion.form.steps[2].fields[1].name')">
                        {{ $t('conversion.form.steps[2].fields[1].label') }}*
                      </label>
                      <input type="text"
                             :name="$t('conversion.form.steps[2].fields[1].name')"
                             :placeholder="$t('conversion.form.steps[2].fields[1].placeholder')"
                             required
                             @focus="tabActiveContent"
                             tabindex="9">
                      <div class="error-msg">
                        {{ $t('conversion.form.steps[2].fields[1].error') }}
                      </div>
                    </div>
                  </div>
                  <div class="col-5  col-sm-10  col-sm-offset-1  col-xs-12  col-xs-offset-0  mb-s">
                    <div class="input-wrapper">
                      <label :for="$t('conversion.form.steps[2].fields[3].name')">
                        {{ $t('conversion.form.steps[2].fields[3].label') }}*
                      </label>
                      <input type="email"
                             :name="$t('conversion.form.steps[2].fields[3].name')"
                             :placeholder="$t('conversion.form.steps[2].fields[3].placeholder')"
                             required
                             @focus="tabActiveContent"
                             tabindex="10">
                      <div class="error-msg">
                        {{ $t('conversion.form.steps[2].fields[3].error') }}
                      </div>
                    </div>
                  </div>
                  <div class="col-5  col-offset-1  col-sm-10  col-xs-12  col-xs-offset-0  mb-s">
                    <div class="input-wrapper">
                      <label :for="$t('conversion.form.steps[2].fields[0].name')">
                        {{ $t('conversion.form.steps[2].fields[0].label') }}*
                      </label>
                      <div class="select-wrapper">
                        <select :name="$t('conversion.form.steps[2].fields[0].name')" required
                                tabindex="11"
                                @focus="tabActiveContent">
                          <option disabled selected value="" hidden>
                            {{ $t('conversion.form.steps[2].fields[0].placeholder') }}
                          </option>
                          <option v-for="value in $t('conversion.form.steps[2].fields[0].values')"
                                  :key="value">
                            {{ value }}
                          </option>
                        </select>
                      </div>
                      <div class="error-msg">
                        {{ $t('conversion.form.steps[2].fields[0].error') }}
                      </div>
                    </div>
                  </div>
                  <div class="col-5  col-sm-10  col-sm-offset-1  col-xs-12  col-xs-offset-0  mb-s">
                    <div class="input-wrapper">
                      <label :for="$t('conversion.form.steps[2].fields[2].name')">
                        {{ $t('conversion.form.steps[2].fields[2].label') }}*
                      </label>
                      <input type="text"
                             :name="$t('conversion.form.steps[2].fields[2].name')"
                             :placeholder="$t('conversion.form.steps[2].fields[2].placeholder')"
                             required
                             @focus="tabActiveContent"
                             tabindex="12">
                      <div class="error-msg">
                        {{ $t('conversion.form.steps[2].fields[2].error') }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row  mt-s">
            <div class="col-12">
              <div class="input-wrapper  checkbox-wrapper  mb-s">
                <input type="checkbox" name="consent" id="consent" tabindex="13" required>
                <label for="consent" v-html="$t('conversion.form.consent.label')"></label>
                <div class="error-msg">{{ $t('conversion.form.consent.error') }}</div>
              </div>
              <p class="font-size-s  color-grey-400  text-mb-m">
                * {{ $t('conversion.form.required') }}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="submit">
                <Button @click.native="handleFormSubmit" class="-yellow" tabindex="14">
                  {{ $t('conversion.form.buttons.submit') }}
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="result  -success" v-if="mailResult === 'success'" key="success">
        <div class="row">
          <div class="col-7  col-offset-1">
            <h6 class="h6  color-brand-blue">
              <Icon class="-inline  -is-left">
                <MailSuccessSVG></MailSuccessSVG>
              </Icon>
              {{ $t('conversion.form.submit.success.title') }}
            </h6>
            <p>{{ $t('conversion.form.submit.success.paragraph') }}</p>
          </div>
        </div>
      </div>
      <div class="result  -error" v-if="mailResult === 'error'" key="error">
        <div class="row">
          <div class="col-7  col-offset-1">
            <h6 class="h6  color-brand-blue">{{ $t('conversion.form.submit.error.title') }}</h6>
            <p>{{ $t('conversion.form.submit.error.paragraph') }}</p>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import axios from 'axios';

import Button from '@/components/atoms/Button.vue';
import Icon from '@/components/atoms/Icon.vue';
import Anchor from '@/components/atoms/Link.vue';

import ArrowSVG from '@/assets/icons/arrow.svg';
import MailSuccessSVG from '@/assets/icons/email-check.svg';

export default {
  name: 'ContactForm',
  props: {
    products: Array,
  },
  data() {
    return {
      // products: [],
      action: '',
      mailResult: 'input',
    };
  },
  mounted() {
    // this.products = this.$t('conversion.profile_1.products.tabs[0].product_keys');
    this.action = window.appConfig.urls.phpmailer;

    this.closestPolyfill();
  },
  methods: {
    productImage(productKey) {
      // eslint-disable-next-line
      return require(`@/assets/products/${productKey}.png`);
    },

    toggleContent(e) {
      e.currentTarget.parentNode.classList.toggle('-is-active');
      this.sendEvent(`Accordion Item ${e.currentTarget.parentNode.dataset.name}`);
    },

    tabActiveContent(e) {
      const accordionItem = e.currentTarget.closest('.accordion-item');

      if (!accordionItem.classList.contains('-is-active')) {
        accordionItem.classList.add('-is-active');
      }
    },

    nextStep(e) {
      const accordionItem = e.currentTarget.closest('.accordion-item');

      accordionItem.nextSibling.classList.add('-is-active');
      accordionItem.nextSibling.querySelector('select, input').focus();

      this.sendEvent(`Next Step after ${accordionItem.dataset.name}`);
    },

    handleFormSubmit(e) {
      e.preventDefault();

      if (!this.$refs.form.checkValidity()) {
        // not valid
        // validate each input to get errors
        this.sendEvent('Form not valid');
        this.validateInputs();
      } else {
        // valid
        this.submit();
      }
    },

    validateInputs() {
      const inputs = [...this.$refs.form.querySelectorAll('input, select')];

      inputs.forEach((input) => {
        if (!input.checkValidity()) {
          this.showInputError(input);
          input.addEventListener('change', this.validateOnChange.bind(this));
        }
      });
    },

    showInputError(input) {
      const accordionItem = input.closest('.accordion-item');

      if (accordionItem) {
        accordionItem.classList.add('-is-active', '-has-error');
      }

      input.closest('.input-wrapper').classList.add('-has-error');
    },

    validateOnChange(e) {
      if (e.currentTarget.checkValidity()) {
        e.currentTarget.closest('.input-wrapper').classList.remove('-has-error');
        e.currentTarget.removeEventListener('change', this.validateOnChange);
      }
    },

    submit() {
      axios.post(this.action, this.getFormData())
        .then((response) => {
          this.mailResult = response.data.mail;
          this.$el.scrollIntoView(true);
          this.sendEvent('Form submitted');
        })
        .catch((error) => {
          this.mailResult = 'error';
          console.error(error);
          this.sendEvent('Form submit error');
        });
    },

    getFormData() {
      const formElements = this.$refs.form.elements;
      const data = {
        locale: this.$i18n.locale,
        product: '',
      };

      for (let i = 0; i < formElements.length; i += 1) {
        if (formElements[i].type !== 'submit') {
          switch (formElements[i].type) {
            case 'radio':
              if (formElements[i].checked) {
                data[formElements[i].name] = formElements[i].value;
              }
              break;
            case 'checkbox':
              data[formElements[i].name] = formElements[i].checked;
              break;
            default:
              data[formElements[i].name] = formElements[i].value;
              break;
          }
        }
      }

      return data;
    },

    sendEvent(label) {
      if (typeof gtag !== 'undefined') {
        gtag('event', 'click', {
          event_category: 'Contact Form',
          event_label: label,
        });
      }
    },

    /* eslint-disable */
    closestPolyfill() {
      if (!Element.prototype.matches) {
        Element.prototype.matches = Element.prototype.msMatchesSelector ||
          Element.prototype.webkitMatchesSelector;
      }

      if (!Element.prototype.closest) {
        Element.prototype.closest = function(s) {
          var el = this;

          do {
            if (el.matches(s)) return el;
            el = el.parentElement || el.parentNode;
          } while (el !== null && el.nodeType === 1);
          return null;
        };
      }
    },
    /* eslint-enable */
  },
  components: {
    Button,
    Icon,
    Anchor,
    ArrowSVG,
    MailSuccessSVG,
  },
};
</script>

<style scoped lang="scss" src="@/sass/07_elements/form.scss"></style>
<style scoped lang="scss" src="@/sass/07_elements/accordion-item.scss"></style>
<style scoped lang="scss" src="@/sass/08_modules/contact-form.scss"></style>
