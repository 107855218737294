<template>
  <footer>
    <div class="row  -full-width">
      <div class="col-9  col-xs-12  pr-0  pl-md-0">
        <div class="_content">
          <div class="row">
            <div class="col-5  col-sm-3  col-xs-10  col-xs-offset-1">
              <Logo href="https://www.bilstein.com" target="_blank">
                <TextLogoSVG></TextLogoSVG>
              </Logo>
            </div>
            <div class="col-6  col-sm-7  col-sm-offset-1  col-xs-10  col-xs-offset-1">
              <nav>
                <a v-for="(link, index) in $t('footer.links')"
                   :key="index"
                   :href="link.url"
                   target="_blank">
                  {{ link.title }}
                  <Icon class="-inline  -is-right  -rotate-270">
                    <ArrowIcon></ArrowIcon>
                  </Icon>
                </a>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div class="col-4  col-pull-1  col-xs-12  col-xs-offset-0  pl-0  pr-xs-0  valign-bottom">
        <div class="_info">
          <p class="mb-0">&copy; BILSTEIN 2019</p>
          <AnimatedLines v-if="!$mq.xs"></AnimatedLines>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import Icon from '@/components/atoms/Icon.vue';
import Logo from '@/components/atoms/Logo.vue';
import AnimatedLines from '@/components/elements/AnimatedLines.vue';

import ArrowIcon from '@/assets/icons/arrow.svg';
import TextLogoSVG from '@/assets/bilstein-text-logo.svg';

export default {
  name: 'Footer',
  components: {
    Icon,
    Logo,
    AnimatedLines,
    ArrowIcon,
    TextLogoSVG,
  },
};
</script>

<style scoped lang="scss" src="@/sass/08_modules/footer.scss"></style>
