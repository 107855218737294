<template>
  <div class="product-teaser">
    <div class="image">
      <img :src="productImage">
    </div>
    <div class="content">
      <div class="name">
        {{ product.name }}
      </div>
      <p class="shortinfo">
        {{ product.shortinfo }}
      </p>
    </div>
    <div class="bottom">
      <Button v-if="product.details" @click.native="openDetails()" class="-yellow  mb-s">
        {{ $t('conversion.buttons.details.expand') }}
        <Icon class="-inline  -is-right">
          <ArrowExpandSVG></ArrowExpandSVG>
        </Icon>
      </Button>
      <div v-if="product.tags" class="tags">
        <span v-for="tag in product.tags" :key="tag" class="tag">{{ tag }}</span>
      </div>
      <Button v-if="product.button"
              class="-yellow  -full-width"
              :href="product.button.url"
              target="_blank"
              @click.native="sendEvent(`${product.button.key} button`)">
        <span v-if="product.button.key !== 'shop' ">
          {{ $t(`conversion.buttons.${product.button.key}`) }}
        </span>
        <span v-if="product.button.key === 'shop' ">
          {{ $t(`${product.button.price}`) }}
        </span>
        <Icon class="-inline  -is-right">
          <CartSVG v-if="product.button.key === 'shop'"></CartSVG>
          <OpenInNewSVG v-if="product.button.key === 'dealer'"></OpenInNewSVG>
        </Icon>
      </Button>
    </div>
  </div>
</template>

<script>
import Button from '@/components/atoms/Button.vue';
import Icon from '@/components/atoms/Icon.vue';

import ArrowExpandSVG from '@/assets/icons/arrow-expand.svg';
import CartSVG from '@/assets/icons/cart.svg';
import OpenInNewSVG from '@/assets/icons/open-in-new.svg';

export default {
  name: 'ProductTeaser',
  props: {
    product_key: String,
    bus: Object,
  },
  data() {
    return {
      showDetails: false,
    };
  },
  computed: {
    product() {
      return this.$t(`products.${this.product_key}`);
    },

    productImage() {
      // eslint-disable-next-line
      return require(`@/assets/products/${this.product_key}.png`);
    },
  },
  methods: {
    openDetails() {
      this.bus.$emit('open-details', this.product_key);
    },

    sendEvent(label) {
      if (typeof gtag !== 'undefined') {
        gtag('event', 'click', {
          event_category: 'Product Teaser',
          event_label: label,
        });
      }
    },
  },
  components: {
    Button,
    Icon,
    ArrowExpandSVG,
    CartSVG,
    OpenInNewSVG,
  },
};
</script>

<style scoped lang="scss" src="@/sass/07_elements/product-teaser.scss"></style>
