<template>
  <header class="header-bar" :class="{ '-fixed': fixed, '-hide': hide }">
    <div class="site-inner">
      <div class="row">
        <div class="col-2  col-xs-3  valign-middle">
          <Logo href="/">
            <BilsteinLogo></BilsteinLogo>
          </Logo>
        </div>
        <div class="col-10  col-xs-9  valign-middle  text-right">
          <MainNav></MainNav>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import Logo from '@/components/atoms/Logo.vue';
import MainNav from '@/components/elements/MainNav.vue';

import BilsteinLogo from '@/assets/bilstein-logo.svg';

export default {
  name: 'HeaderBar',
  data() {
    return {
      lastY: 0,
      normalY: 0,
      fixed: false,
      hide: false,
    };
  },
  mounted() {
    this.normalY = this.$el.scrollTop + this.$el.getBoundingClientRect().height;
    window.requestAnimationFrame(this.handleFrame.bind(this));
  },
  methods: {
    controlShow() {
      if (!this.fixed) {
        if (window.pageYOffset > 0) {
          this.fixed = true;
          this.hide = true;
        }
      } else {
        // eslint-disable-next-line
        if (window.pageYOffset <= 0) {
          this.fixed = false;
          this.hide = false;
        } else if (this.hide) {
          if (this.lastY > window.pageYOffset) {
            this.hide = false;
          }
        } else if (!this.hide) {
          if (this.lastY < window.pageYOffset) {
            this.hide = true;
          }
        }
      }
    },

    handleFrame() {
      this.controlShow();

      this.lastY = window.pageYOffset;
      window.requestAnimationFrame(this.handleFrame.bind(this));
    },
  },
  components: {
    Logo,
    MainNav,
    BilsteinLogo,
  },
};
</script>

<style scoped lang="scss" src="@/sass/08_modules/header-bar.scss"></style>
