<template>
  <div class="product-usp" :data-number="dataNumber" :class="`-${position}`">
    <component v-if="!$mq.sm" :is="markerIcon(position)"></component>
    <div class="_text">
      <h4 class="h4  mb-m-less">{{ usp.headline }}</h4>
      <p>{{ usp.paragraph }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductUsp',
  props: {
    usp: Object,
    dataNumber: Number,
    position: String,
  },
  methods: {
    markerIcon(name) {
      // eslint-disable-next-line
      return require(`@/assets/icons/usp-marker_${name}.svg`).default;
    },
  },
};
</script>

<style scoped lang="scss" src="@/sass/07_elements/product-usp.scss"></style>
