<template>
  <div class="home">
    <LoadingScreen @finishedLoading="onLoaded"></LoadingScreen>

    <StoryInsight :loaded="loaded"></StoryInsight>

    <ProductStage></ProductStage>

    <ConversionTeaser></ConversionTeaser>

    <SocialTeaser></SocialTeaser>
  </div>
</template>

<script>
// @ is an alias to /src
import LoadingScreen from '@/components/modules/LoadingScreen.vue';
import StoryInsight from '@/components/modules/StoryInsight.vue';
import ProductStage from '@/components/modules/ProductStage.vue';
import ConversionTeaser from '@/components/modules/ConversionTeaser.vue';
import SocialTeaser from '@/components/modules/SocialTeaser.vue';

export default {
  name: 'home',
  data() {
    return {
      loaded: false,
    };
  },
  methods: {
    onLoaded() {
      this.loaded = true;
    },
  },
  components: {
    LoadingScreen,
    StoryInsight,
    ProductStage,
    ConversionTeaser,
    SocialTeaser,
  },
};
</script>
