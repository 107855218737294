<template>
  <nav id="nav" class="main-nav">
    <a href="#product" @click="sendEvent('Product')">
      {{ $t('navigation.product-stage') }}
    </a>
    <a href="#contact" @click="sendEvent('Contact')">
      {{ $t('navigation.conversion-teaser') }}
    </a>
    <span class="language-switch">
      <a href="javascript:void(0)"
         v-if="!$mq.xs"
         :class="{ '-is-active': $i18n.locale === languages[0].code }"
         @click="changeLocale(languages[0].code)">
        {{ languages[0].title }}
      </a>
      <span v-if="!$mq.xs">&nbsp;|&nbsp;</span>
      <a href="javascript:void(0)"
         v-if="!$mq.xs"
         :class="{ '-is-active': $i18n.locale === languages[1].code }"
         @click="changeLocale(languages[1].code)">
        {{ languages[1].title }}
      </a>
      <a href="javascript:void(0)"
         v-if="$mq.xs"
         @click="changeLocale(switchToLang.code)">
        {{ switchToLang.title }}
      </a>
    </span>
  </nav>
</template>

<script>
import i18n from '@/i18n';
import router from '@/router';

export default {
  name: 'MainNav',
  data() {
    return {
      languages: [
        { code: 'en', title: 'En' },
        { code: 'de', title: 'De' },
      ],
    };
  },
  computed: {
    switchToLang() {
      // eslint-disable-next-line
      return (this.$i18n.locale === this.languages[0].code) ? this.languages[1] : this.languages[0];
    },
  },
  methods: {
    changeLocale(locale) {
      i18n.locale = locale;
      router.push(`/${locale}`);

      this.sendEvent(locale);
    },

    sendEvent(label) {
      if (typeof gtag !== 'undefined') {
        gtag('event', 'click', {
          event_category: 'Main Nav',
          event_label: label,
        });
      }
    },
  },
};
</script>

<style scoped lang="scss" src="@/sass/07_elements/main-nav.scss"></style>
