<template>
  <div class="animated-lines">
    <div v-for="n in count" :key="n" class="line-wrapper">
      <div class="line" :class="classes[n-1]"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AnimatedLines',
  props: {
    count: {
      type: Number,
      default: 3,
    },
  },
  data() {
    return {
      attributes: [
        'pos', 'width', 'height', 'color', 'delay', 'duration',
      ],
      classes: [],
    };
  },
  mounted() {
    this.randomizeClasses();
  },
  methods: {
    randomizeClasses() {
      this.attributes.forEach((attr) => {
        const randArray = [1, 2, 3];
        randArray.sort(() => 0.5 - Math.random());

        for (let i = 0; i < this.count; i += 1) {
          if (!this.classes[i]) this.classes[i] = ' ';
          this.classes.splice(i, 1, `${this.classes[i]}${attr}-${randArray[i]}  `);
        }
      });
    },
  },
};
</script>

<style scoped lang="scss" src="@/sass/07_elements/animated-lines.scss"></style>
