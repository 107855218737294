<template>
  <div id="app" class="site">
    <HeaderBar></HeaderBar>

    <router-view/>

    <Footer></Footer>
  </div>
</template>

<style lang="scss" src="./sass/style.scss"></style>

<script>
import HeaderBar from '@/components/modules/HeaderBar.vue';
import Footer from '@/components/modules/Footer.vue';

export default {
  name: 'App',
  components: {
    HeaderBar,
    Footer,
  },
  metaInfo() {
    return {
      title: this.$t('meta.title'),
      meta: [
        {
          name: 'description',
          content: this.$t('meta.description'),
        },
        {
          property: 'og:title',
          content: this.$t('meta.og.title'),
        },
        {
          property: 'og:description',
          content: this.$t('meta.og.description'),
        },
        {
          property: 'og:image',
          content: this.$t('meta.og.image'),
        },
      ],
    };
  },
};
</script>
