import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/Home.vue';
import i18n from './i18n';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/:lang',
      component: Home,
      beforeEnter(to, from, next) {
        const { lang } = to.params;

        if (!['en', 'de'].includes(lang)) return next('en');

        if (i18n.locale !== lang) {
          i18n.locale = lang;
        }

        return next();
      },
    },
  ],
});
