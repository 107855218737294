import Vue from 'vue';
import VueScrollmagic from 'vue-scrollmagic';
import VueMatchMedia from 'vue-match-media/dist/index';
import VueCheckView from 'vue-check-view';
import VueMeta from 'vue-meta';
import App from './App.vue';
import router from './router';
import i18n from './i18n';

import appConfig from '@/config/';

Vue.config.productionTip = false;
Vue.use(VueScrollmagic);
Vue.use(VueMatchMedia);
Vue.use(VueCheckView);
Vue.use(VueMeta);

appConfig();

const breakpoints = {
  lg: 1800,
  md: 1200,
  sm: 900,
  xs: 600,
  page: 1248,
};

new Vue({
  router,
  i18n,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event('x-app-rendered')),
  mq: {
    xl: `(min-width: ${breakpoints.lg + 1}px)`,
    lg: `(max-width: ${breakpoints.lg}px)`,
    md: `(max-width: ${breakpoints.md}px)`,
    sm: `(max-width: ${breakpoints.sm}px)`,
    xs: `(max-width: ${breakpoints.xs}px)`,
    onlyLg: `(min-width: ${breakpoints.md + 1}px) and (max-width: ${breakpoints.lg}px)`,
    onlyMd: `(min-width: ${breakpoints.sm + 1}px) and (max-width: ${breakpoints.md}px)`,
    onlySm: `(min-width: ${breakpoints.xs + 1}px) and (max-width: ${breakpoints.sm}px)`,
    mdAndUp: `(min-width: ${breakpoints.md}px)`,
    smAndUp: `(min-width: ${breakpoints.sm}px)`,
    xsAndUp: `(min-width: ${breakpoints.xs}px)`,
    pageMaxSize: `(max-width: ${breakpoints.page}px)`,
  },
}).$mount('#app');
