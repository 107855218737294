<template>
  <div class="product-details">
    <ContentOverlay :ref="key"
                    v-for="(product, key) in productDetails"
                    :key="key"
                    :id="key"
                    class="text-left">
      <div class="row  display-flex">
        <div class="col-4  col-xs-12" v-if="!$mq.xs">
          <div class="image">
            <img :src="productImage(key)">
          </div>
        </div>
        <div class="col-8  col-xs-12  info">
          <div class="h5">{{ product.name }}</div>
          <p class="mb-m">{{ product.details.description }}</p>
          <Anchor v-if="product.details.download.url"
                  :href="product.details.download.url"
                  class="-block  mb-m"
                  download>
            <Icon class="-inline  -is-left">
              <DownloadSVG></DownloadSVG>
            </Icon>
            {{ product.details.download.title }}
          </Anchor>
          <div class="h6">{{ product.details.features.headline }}</div>
          <ul class="mb-0">
            <li v-for="(feature, index) in product.details.features.list" :key="feature + index">
              {{ feature }}
            </li>
          </ul>
        </div>
      </div>
    </ContentOverlay>
  </div>
</template>

<script>
import Anchor from '@/components/atoms/Link.vue';
import Icon from '@/components/atoms/Icon.vue';
import ContentOverlay from '@/components/elements/ContentOverlay.vue';

import DownloadSVG from '@/assets/icons/download.svg';

export default {
  name: 'ProductDetails',
  props: {
    bus: Object,
  },
  data() {
    return {
      activeProduct: '',
    };
  },
  computed: {
    productDetails() {
      const productKeysWithDetails = Object.keys(this.$t('products')).filter(productKey => this.$t(`products.${productKey}`).details);
      const productsWithDetails = {};

      productKeysWithDetails.forEach((key) => {
        productsWithDetails[key] = this.$t(`products.${key}`);
      });

      return productsWithDetails;
    },
  },
  mounted() {
    this.bus.$on('open-details', this.toggleDetails);
  },
  methods: {
    productImage(productKey) {
      // eslint-disable-next-line
      return require(`@/assets/products/${productKey}.png`);
    },

    toggleDetails(productKey) {
      this.$refs[productKey][0].toggle();
      this.sendEvent(productKey);
    },

    sendEvent(label) {
      if (typeof gtag !== 'undefined') {
        gtag('event', 'click', {
          event_category: 'Product Details',
          event_label: label,
        });
      }
    },
  },
  components: {
    Anchor,
    Icon,
    ContentOverlay,
    DownloadSVG,
  },
};
</script>

<style scoped lang="scss" src="@/sass/07_elements/product-details.scss"></style>
