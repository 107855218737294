<template>
  <transition
    name="slide-fade"
    :before-enter="beforeEnter"
    :enter="enter"
    :before-leave="beforeLeave"
    :leave="leave">
    <slot></slot>
  </transition>
</template>

<script>
export default {
  name: 'SlideFade',
  methods: {
    beforeEnter() {

    },

    enter() {

    },

    beforeLeave() {

    },

    leave() {

    },
  },
};
</script>

<style scoped lang="scss" src="@/sass/07_elements/slidefade-transition.scss"></style>
