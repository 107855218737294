<template>
  <section class="story-insight" :class="{ '-wait-for-load': !loaded}">
    <div class="scene-wrapper" ref="sceneWrapper">
      <div class="scene" data-number="1" ref="scene1">
        <div class="images">
          <img ref="scene1NearLayer" class="layer  -front"
               src="@/assets/story/scene1/grass.png"
               srcset="@/assets/story/scene1/grass.png 2100w,
               @/assets/story/scene1/grass-1058x700.png 1000w"
               sizes="100vw">
          <img ref="scene1FrontLayer" class="layer  -front"
               src="@/assets/story/scene1/car.png"
               srcset="@/assets/story/scene1/car.png 2100w,
               @/assets/story/scene1/car-1058x700.png 1000w"
               sizes="100vw">
          <img ref="scene1MidLayer" class="layer  -mid"
               src="@/assets/story/scene1/track.png"
               srcset="@/assets/story/scene1/track.png 2100w,
               @/assets/story/scene1/track-1058x700.png 1000w"
               sizes="100vw">
          <img ref="scene1BackLayer" class="layer  -back"
               src="@/assets/story/scene1/stand.png"
               srcset="@/assets/story/scene1/stand.png 2100w,
               @/assets/story/scene1/stand-1058x700.png 1000w"
               sizes="100vw">
        </div>
        <div class="text" ref="scene1Text">
          <div class="site-inner">
            <div class="row">
              <div class="col-6  col-sm-11">
                <h1 class="h1">{{ $t('story.scene1.title') }}</h1>
              </div>
            </div>
            <div class="row">
              <div class="col-6  col-offset-1  col-sm-12  col-sm-offset-0">
                <p>{{ $t('story.scene1.paragraph') }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="scene" data-number="2" ref="scene2">
        <div class="images">
          <img ref="scene2FrontLayer" class="layer  -front"
               src="@/assets/story/scene2/steering.png"
               srcset="@/assets/story/scene2/steering.png 2100w,
               @/assets/story/scene2/steering-1058x700.png 1000w"
               sizes="100vw">
          <img ref="scene2NearLayer" class="layer  -front"
               src="@/assets/story/scene2/fittings.png"
               srcset="@/assets/story/scene2/fittings.png 2100w,
               @/assets/story/scene2/fittings-1058x700.png 1000w"
               sizes="100vw">
          <img ref="scene2MidLayer" class="layer  -mid"
               src="@/assets/story/scene2/driver.png"
               srcset="@/assets/story/scene2/driver.png 2100w,
               @/assets/story/scene2/driver-1058x700.png 1000w"
               sizes="100vw">
          <img ref="scene2BackLayer" class="layer  -back"
               src="@/assets/story/scene2/interior.png"
               srcset="@/assets/story/scene2/interior.png 2100w,
               @/assets/story/scene2/interior-1058x700.png 1000w"
               sizes="100vw">
        </div>
        <div class="text" ref="scene2Text">
          <div class="site-inner">
            <div class="row">
              <div class="col-5  col-sm-11">
                <h2 class="h1">{{ $t('story.scene2.title') }}</h2>
              </div>
            </div>
            <div class="row">
              <div class="col-6  col-offset-1  col-sm-12  col-sm-offset-0">
                <p>{{ $t('story.scene2.paragraph') }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="scene" data-number="3" ref="scene3">
        <div class="images">
          <img ref="scene3FrontLayer" class="layer  -front"
               src="@/assets/story/scene3/technician.png"
               srcset="@/assets/story/scene3/technician.png 2100w,
               @/assets/story/scene3/technician-1058x700.png 1000w"
               sizes="100vw">
          <img ref="scene3MidLayer" class="layer  -mid"
               src="@/assets/story/scene3/body.png"
               srcset="@/assets/story/scene3/body.png 2100w,
               @/assets/story/scene3/body-1058x700.png 1000w"
               sizes="100vw">
          <img ref="scene3BackLayer" class="layer  -back"
               src="@/assets/story/scene3/damper.png"
               srcset="@/assets/story/scene3/damper.png 2100w,
               @/assets/story/scene3/damper-1058x700.png 1000w"
               sizes="100vw">
        </div>
        <div class="text" ref="scene3Text">
          <div class="site-inner">
            <div class="row">
              <div class="col-6  col-sm-11">
                <h2 class="h1">{{ $t('story.scene3.title') }}</h2>
              </div>
            </div>
            <div class="row">
              <div class="col-6  col-offset-1  col-sm-12  col-sm-offset-0">
                <p>{{ $t('story.scene3.paragraph') }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="progress-wrapper" v-show="!$mq.md">
        <TrackSVG ref="progressLine"></TrackSVG>
      </div>
    </div>
  </section>
</template>

<script>
import { TweenMax, TimelineMax, Power1 } from 'gsap/all';
import TrackSVG from '@/assets/story/track.svg';

export default {
  name: 'StoryInsight',
  props: {
    loaded: Boolean,
  },
  mounted() {
    this.progressLine = this.$refs.progressLine.querySelector('path[data-name="progressLine"]');
    this.initAnim();
  },
  methods: {
    initAnim() {
      const tl = new TimelineMax();
      const fromVars = {
        yPercent: '-50',
        xPercent: '-50',
        y: 0,
        x: 0,
        scale: 1,
      };

      tl.add([
        TweenMax.fromTo(this.$refs.scene1NearLayer, 2, fromVars, {
          scale: 1.5,
        }),
        TweenMax.fromTo(this.$refs.scene1FrontLayer, 2, fromVars, {
          yPercent: '-45',
          xPercent: (!this.$mq.sm) ? '-67' : '-50',
          x: (this.$mq.sm) ? -200 : 0,
          scale: 1.75,
          ease: Power1.easeOut,
        }),
        TweenMax.fromTo(this.$refs.scene1MidLayer, 2, fromVars, {
          scale: 1.3,
          ease: Power1.easeOut,
        }),
        TweenMax.fromTo(this.$refs.scene1BackLayer, 2, fromVars, {
          scale: 1.1,
          ease: Power1.easeOut,
        }),
      ])
      // eslint-disable-next-line
        .fromTo([this.$refs.scene1NearLayer, this.$refs.scene1FrontLayer, this.$refs.scene1MidLayer, this.$refs.scene1BackLayer, this.$refs.scene1Text], .7, {
          opacity: 1,
        }, {
          opacity: 0,
        }, '-=0')
        // eslint-disable-next-line
        .fromTo([this.$refs.scene2NearLayer, this.$refs.scene2FrontLayer, this.$refs.scene2MidLayer, this.$refs.scene2BackLayer], .7, {
          opacity: 0,
        }, {
          opacity: 1,
        }, '-=1')
        .staggerFromTo([this.$refs.scene2Text.querySelector('.h1'), this.$refs.scene2Text.querySelector('p')], 0.7, {
          opacity: 0,
          yPercent: 25,
        }, {
          yPercent: 0,
          opacity: 1,
          ease: Power1.easeOut,
        }, 0.4);


      tl.add([
        TweenMax.fromTo(this.$refs.scene2NearLayer, 2, fromVars, {
          scale: 1.75,
          ease: Power1.easeOut,
        }),
        TweenMax.fromTo(this.$refs.scene2FrontLayer, 2, fromVars, {
          scale: 1.75,
          rotation: 10,
          ease: Power1.easeOut,
        }),
        TweenMax.fromTo(this.$refs.scene2MidLayer, 2, fromVars, {
          scale: 1.3,
          rotation: 2.5,
          ease: Power1.easeOut,
        }),
        TweenMax.fromTo(this.$refs.scene2BackLayer, 2, fromVars, {
          scale: 1.1,
          ease: Power1.easeOut,
        }),
      ], '+=0.25')
      // eslint-disable-next-line
        .fromTo([this.$refs.scene2NearLayer, this.$refs.scene2FrontLayer, this.$refs.scene2MidLayer, this.$refs.scene2BackLayer, this.$refs.scene2Text], .7, {
          opacity: 1,
        }, {
          opacity: 0,
        }, '-=0')
        // eslint-disable-next-line
        .fromTo([this.$refs.scene3FrontLayer, this.$refs.scene3MidLayer, this.$refs.scene3BackLayer], .7, {
          opacity: 0,
        }, {
          opacity: 1,
        }, '-=1')
        .staggerFromTo([this.$refs.scene3Text.querySelector('.h1'), this.$refs.scene3Text.querySelector('p')], 1, {
          opacity: 0,
          yPercent: 25,
        }, {
          yPercent: 0,
          opacity: 1,
          ease: Power1.easeOut,
        }, 0.4);

      tl.add([
        TweenMax.fromTo(this.$refs.scene3FrontLayer, 2, fromVars, {
          scale: 1.75,
          ease: Power1.easeOut,
        }),
        TweenMax.fromTo(this.$refs.scene3MidLayer, 2, fromVars, {
          scale: 1.35,
          ease: Power1.easeOut,
        }),
        TweenMax.fromTo(this.$refs.scene3BackLayer, 2, fromVars, {
          scale: 1.25,
          ease: Power1.easeOut,
        }),
      ], '+=0.25')
      // eslint-disable-next-line
        .fromTo([this.$refs.scene3FrontLayer, this.$refs.scene3MidLayer, this.$refs.scene3BackLayer, this.$refs.scene3Text], 1, {
          opacity: 1,
        }, {
          opacity: 1,
        }, '-=0.1');

      const storyScrollScene = this.$scrollmagic.scene({
        triggerElement: this.$refs.sceneWrapper,
        triggerHook: 0,
        duration: window.outerHeight * 6,
      })
        .setPin(this.$refs.sceneWrapper)
        .setTween(tl);

      storyScrollScene.on('progress', (event) => {
        // const mesh = Math.max(0, event.progress * (event.progress - 0.5));
        // const normalize = Math.max(0, event.progress * (event.progress - 0.75));
        // const add = (mesh > 0.25) ? 0.25 : mesh;
        // const multiplier = event.progress - (add - normalize);
        const multiplier = event.progress;

        this.progressLine.style.strokeDashoffset = 1600 - (multiplier * 1600);
      });

      this.$scrollmagic.addScene(storyScrollScene);
    },
  },
  watch: {
    loaded() {
      const tl = new TimelineMax();
      const toVars = {
        yPercent: '-50',
        xPercent: '-50',
        scale: 1,
        y: 0,
        x: 0,
      };

      tl.add([
        TweenMax.fromTo(this.$refs.scene1NearLayer, 2, {
          yPercent: '-50',
          xPercent: '-50',
          scale: 1.5,
        }, toVars),
        TweenMax.fromTo(this.$refs.scene1FrontLayer, 2, {
          yPercent: '-45',
          xPercent: '-67',
          scale: 1.75,
        }, toVars),
        TweenMax.fromTo(this.$refs.scene1MidLayer, 2, {
          yPercent: '-50',
          xPercent: '-50',
          scale: 1.3,
        }, toVars),
        TweenMax.fromTo(this.$refs.scene1BackLayer, 2, {
          yPercent: '-50',
          xPercent: '-50',
          scale: 1.1,
        }, toVars),
      ]);

      tl.play();
    },
  },
  components: {
    TrackSVG,
  },
};
</script>

<style scoped lang="scss" src="@/sass/08_modules/story-insight.scss"></style>
