<template>
  <section class="social-teaser">
    <div class="site-inner">
      <div class="row">
        <div class="col-12  text-center">
          <p class="_pre-headline">{{ $t('socialTeaser.preheadline') }}</p>
          <h4 class="h4">{{ $t('socialTeaser.headline') }}</h4>
          <nav>
            <a v-if="$t('socialTeaser.facebook')"
               :href="$t('socialTeaser.facebook')"
               target="_blank">
              <Icon class="-inline"><FacebookSVG></FacebookSVG></Icon>
            </a>
            <a v-if="$t('socialTeaser.twitter')"
               :href="$t('socialTeaser.twitter')"
               target="_blank">
              <Icon class="-inline"><TwitterSVG></TwitterSVG></Icon>
            </a>
            <a v-if="$t('socialTeaser.instagram')"
               :href="$t('socialTeaser.instagram')"
               target="_blank">
              <Icon class="-inline"><InstaSVG></InstaSVG></Icon>
            </a>
            <a v-if="$t('socialTeaser.youtube')"
               :href="$t('socialTeaser.youtube')"
               target="_blank">
              <Icon class="-inline"><YoutubeSVG></YoutubeSVG></Icon>
            </a>
          </nav>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Icon from '@/components/atoms/Icon.vue';

import FacebookSVG from '@/assets/icons/facebook.svg';
import TwitterSVG from '@/assets/icons/twitter.svg';
import InstaSVG from '@/assets/icons/instagram.svg';
import YoutubeSVG from '@/assets/icons/youtube.svg';

export default {
  name: 'SocialTeaser',
  components: {
    Icon,
    FacebookSVG,
    TwitterSVG,
    InstaSVG,
    YoutubeSVG,
  },
};
</script>

<style scoped lang="scss" src="@/sass/08_modules/social-teaser.scss"></style>
