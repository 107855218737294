<template>
  <section class="product-stage" id="product">
    <div ref="transitionEl" class="transition-el"></div>
    <div class="bg-bar"></div>
    <div class="site-inner">
      <div class="row  mb-xl  mb-sm-l">
        <div class="col-12  text-center">
          <transition appear name="slide-fade">
            <h2 class="h2">
              {{ $t('productStage.headline') }}<br>
              <span class="color-brand-blue">
                {{ $t('productStage.headline_2') }}
              </span>
            </h2>
          </transition>
        </div>
        <div class="col-6  col-offset-3  col-sm-12  col-sm-offset-0  text-center">
          <p class="font-size-l">
            {{ $t('productStage.paragraph') }}
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12  text-center" v-if="$mq.sm">
          <div class="mb-l-less">
            <Tabswitch :class="{ '-is-active': !street }"
                       @click.native="tabSwitchHandler(false, 'Rallye Tab')">
              {{ $t('productStage.switch.rallye') }}
            </Tabswitch>
            <Tabswitch :class="{ '-is-active': street }"
                       @click.native="tabSwitchHandler(true, 'Circuit Tab')">
              {{ $t('productStage.switch.street') }}
            </Tabswitch>
          </div>
        </div>
        <transition name="slide-fade" mode="out-in">
          <div v-if="street" class="col-4  col-sm-12  pt-xxl  pt-sm-0  mb-sm-l-less  product-info"
               key="street">
            <h3 class="h3">
              {{ $t('productStage.street.intro.headline') }}
            </h3>
            <p class="mb-m">
              {{ $t('productStage.street.intro.paragraph') }}
            </p>
            <Button href="#contact" class="-yellow" @click.native="sendEvent('Contact CTA')">
              {{ $t('productStage.street.intro.button') }}
              <Icon class="-inline  -is-right">
                <ArrowIcon></ArrowIcon>
              </Icon>
            </Button>
          </div>
          <div v-if="!street" class="col-4  col-sm-12  pt-xxl  pt-sm-0  mb-sm-l-less  product-info"
               key="rallye">
            <h3 class="h3">
              {{ $t('productStage.rallye.intro.headline') }}
            </h3>
            <p class="mb-m">
              {{ $t('productStage.rallye.intro.paragraph') }}
            </p>
            <Button href="#contact" class="-yellow" @click.native="sendEvent('Contact CTA')">
              {{ $t('productStage.rallye.intro.button') }}
              <Icon class="-inline  -is-right">
                <ArrowIcon></ArrowIcon>
              </Icon>
            </Button>
          </div>
        </transition>
        <transition name="slide-fade" mode="out-in" :style="{ '--base-delay': 0.4 }" v-if="$mq.sm">
          <div v-if="street"
               class="col-3  col-offset-1  col-sm-12  col-sm-offset-0  mb-sm-l-less"
               key="street">
            <div>
              <TinySlider v-bind="tinySliderOptions" nav-container="#street-facts-controls">
                <div class="fact"
                     v-for="(fact, index) in $t('productStage.street.facts')"
                     :key="index">
                  <div class="h3  mb-xxs  color-brand-blue  text-center">
                    {{ fact.headline }}
                  </div>
                  <p class="text-center">
                    {{ fact.paragraph }}
                  </p>
                </div>
              </TinySlider>
              <div class="facts-controls" id="street-facts-controls">
                <span v-for="fact in $t('productStage.street.facts')" :key="fact.headline"></span>
              </div>
            </div>
          </div>
          <div v-if="!street"
               class="col-3  col-offset-1  col-sm-12  col-sm-offset-0  mb-sm-l-less"
               key="rallye">
            <div>
              <TinySlider v-bind="tinySliderOptions" nav-container="#rallye-facts-controls">
                <div class="fact"
                     v-for="(fact, index) in $t('productStage.rallye.facts')"
                     :key="index">
                  <div class="h3  mb-xxs  color-brand-blue  text-center">
                    {{ fact.headline }}
                  </div>
                  <p class="text-center">
                    {{ fact.paragraph }}
                  </p>
                </div>
              </TinySlider>
              <div class="facts-controls" id="rallye-facts-controls">
                <span v-for="fact in $t('productStage.rallye.facts')" :key="fact.headline"></span>
              </div>
            </div>
          </div>
        </transition>
        <div class="col-4  col-sm-12  text-center">
          <div class="mb-l" v-if="!$mq.sm">
            <Tabswitch :class="{ '-is-active': !street }"
                       @click.native="tabSwitchHandler(false, 'Rallye Tab')">
              {{ $t('productStage.switch.rallye') }}
            </Tabswitch>
            <Tabswitch :class="{ '-is-active': street }"
                       @click.native="tabSwitchHandler(true, 'Circuit Tab')">
              {{ $t('productStage.switch.street') }}
            </Tabswitch>
          </div>
          <div ref="stageWrapper" class="stage-wrapper">
            <div ref="productImageWrapper" class="product-image-wrapper"
                 :class="{ '-street': street, '-rallye': !street }">
              <div ref="productImage" class="product-image">
                <transition name="fade" mode="out-in">
                  <div ref="productImageAnimation"
                       class="_alt"
                       :class="{ '-street': street, '-rallye': !street }"
                       key="street"></div>
                </transition>
                <div v-if="$mq.sm">
                  <div ref="productUspMobileMarker" class="product-usp-mobile-marker"
                       v-for="(usp, index) in $t('productStage.usps')"
                       :key="index"
                       :data-number="index+1">
                    <USPPhoneMarkerSVG></USPPhoneMarkerSVG>
                  </div>
                </div>
              </div>
              <ProductUsp v-for="(usp, index) in $t('productStage.usps')" :key="index"
                          ref="productUsp"
                          :data-number="index+1"
                          :usp="usp"
                          :position="usp.position">
              </ProductUsp>
            </div>
            <div v-for="(usp, index) in $t('productStage.usps')" :key="index"
                 ref="productUspTrigger"
                 class="product-usp-trigger"
                 :data-number="index+1">
            </div>
          </div>
        </div>
        <transition name="slide-fade" mode="out-in" :style="{ '--base-delay': 0.4 }" v-if="!$mq.sm">
          <div v-if="street"
               class="col-3  col-offset-1  col-sm-12  col-sm-offset-0  pt-xxl"
               key="street">
            <div v-for="(fact, index) in $t('productStage.street.facts')" :key="index">
              <div class="mb-m">
                <div class="h3  mb-xxs  color-brand-blue">
                  {{ fact.headline }}
                </div>
                <p>
                  {{ fact.paragraph }}
                </p>
              </div>
            </div>
          </div>
          <div v-if="!street"
               class="col-3  col-offset-1  col-sm-12  col-sm-offset-0  pt-xxl"
               key="rallye">
            <div v-for="(fact, index) in $t('productStage.rallye.facts')" :key="index">
              <div class="mb-m">
                <div class="h3  mb-xxs  color-brand-blue">
                  {{ fact.headline }}
                </div>
                <p>
                  {{ fact.paragraph }}
                </p>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
    <div class="addendum" v-if="!street">
      <div class="_wrapper">
        <div class="site-inner">
          <div class="row">
            <div class="col-9  col-offset-1  col-xs-10">
              <h3 class="h2">{{ $t('productStage.addendum.headline') }}</h3>
            </div>
          </div>
          <div class="row">
            <div class="col-7  col-offset-4  col-xs-10  col-xs-offset-1">
              <p class="mb-0">{{ $t('productStage.addendum.paragraph') }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <SlideFade></SlideFade>
  </section>
</template>

<script>
import { Power1, SteppedEase, TweenMax } from 'gsap/all';

import Button from '@/components/atoms/Button.vue';
import Icon from '@/components/atoms/Icon.vue';
import Tabswitch from '@/components/atoms/Tabswitch.vue';
import ProductUsp from '@/components/elements/ProductUsp.vue';
import SlideFade from '@/components/elements/SlideFade.vue';

import TinySlider from 'vue-tiny-slider';

import ArrowIcon from '@/assets/icons/arrow.svg';
import USPPhoneMarkerSVG from '@/assets/icons/usp-marker_phone.svg';

export default {
  name: 'ProductStage',
  data() {
    return {
      street: false,
      numberFacts: 3,
      numberUsps: 3,
      durationNumber: 1,
      tinySliderOptions: {
        items: 1,
        loop: true,
        controls: false,
        nav: true,
        autoplay: true,
        autoplayButtonOutput: false,
        autoplayHoverPause: true,
      },
    };
  },
  mounted() {
    if (this.$mq.sm) this.durationNumber = 2;
    this.createAnimationScenes();
    this.inTransition();
  },
  methods: {
    createAnimationScenes() {
      const {
        stageWrapper,
        productImageWrapper,
        productImage,
        productImageAnimation,
        productUsp,
        productUspTrigger,
        productUspMobileMarker,
      } = this.$refs;

      const pinScene = this.$scrollmagic.scene({
        triggerElement: stageWrapper,
        triggerHook: 0.25,
        // duration: `${100 * (this.numberUsps + this.durationNumber)}%`,
        duration: window.outerHeight * (this.numberUsps + this.durationNumber),
      });

      if (!this.$mq.sm) {
        pinScene.setPin(productImageWrapper);
      } else {
        pinScene.setPin(productImage);
      }

      const scaleScene = this.$scrollmagic.scene({
        triggerElement: stageWrapper,
        triggerHook: 0.25,
        duration: window.outerHeight,
      }).setTween(productImage, 0.5, {
        scale: (this.$mq.sm) ? 2 : 4,
        ease: Power1.easeInOut,
      });

      const productAnimationScene = this.$scrollmagic.scene({
        triggerElement: stageWrapper,
        triggerHook: 0.25,
        duration: window.outerHeight,
      }).setTween(productImageAnimation, 0.5, {
        backgroundPosition: '100% 50%',
        ease: SteppedEase.config(21),
      });

      const uspScenes = [];
      productUspTrigger.forEach((usp, key) => {
        const uspScene = this.$scrollmagic.scene({
          triggerElement: usp,
          triggerHook: (this.$mq.sm) ? 1 : 0.5,
          tweenChanges: true,
          duration: (this.$mq.sm) ? window.outerHeight * 1.5 : 0,
        })
          .setPin(usp);

        if (this.$mq.sm) {
          uspScene.setClassToggle([productUsp[key].$el, productUspMobileMarker[key]], '-is-active');

          uspScene.setTween(productUsp[key].$el, 1, {
            yPercent: '150%',
          });
        } else {
          uspScene.setClassToggle(productUsp[key].$el, '-is-active');
        }


        uspScenes.push(uspScene);
      });

      if (!this.$mq.xs) {
        this.$scrollmagic.addScene([pinScene, scaleScene, productAnimationScene, ...uspScenes]);
      } else {
        this.$scrollmagic.addScene([pinScene, scaleScene, ...uspScenes]);
      }
    },

    inTransition() {
      const { transitionEl } = this.$refs;

      TweenMax.set(transitionEl, {
        rotation: '-10deg',
        xPercent: '-50%',
      });

      const transitionTween = TweenMax.to(transitionEl, 1, {
        y: '-100%',
        xPercent: '-50%',
        ease: Power1.easeOut,
      });

      const inTransitionScene = this.$scrollmagic.scene({
        triggerElement: this.$el,
        triggerHook: 0.99,
      })
        .setTween(transitionTween)
        .setClassToggle(this.$el, '-is-active');

      inTransitionScene.on('start', ({ scrollDirection }) => {
        if (scrollDirection === 'FORWARD' && this.$el.getBoundingClientRect().bottom > 0) {
          setTimeout(() => {
            window.scrollTo(0, this.$el.offsetTop);
          }, 500);
        }
      });

      this.$scrollmagic.addScene(inTransitionScene);
    },

    tabSwitchHandler(street, eventLabel) {
      this.sendEvent(eventLabel);
      this.street = street;
    },

    sendEvent(label) {
      if (typeof gtag !== 'undefined') {
        const current = (this.street) ? 'Circuit' : 'Rallye';
        gtag('event', 'click', {
          event_category: `Product Stage ${current}`,
          event_label: label,
        });
      }
    },
  },
  components: {
    Button,
    Icon,
    Tabswitch,
    ProductUsp,
    SlideFade,
    TinySlider,
    ArrowIcon,
    USPPhoneMarkerSVG,
  },
};
</script>

<style scoped lang="scss" src="@/sass/08_modules/product-stage.scss"></style>
