<template>
  <div class="tabswitch">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Tabswitch',
};
</script>

<style scoped lang="scss" src="@/sass/06_atoms/tabswitch.scss"></style>
