import { render, staticRenderFns } from "./ContentOverlay.vue?vue&type=template&id=77d6ea52&scoped=true&"
import script from "./ContentOverlay.vue?vue&type=script&lang=js&"
export * from "./ContentOverlay.vue?vue&type=script&lang=js&"
import style0 from "@/sass/07_elements/content-overlay.scss?vue&type=style&index=0&id=77d6ea52&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77d6ea52",
  null
  
)

export default component.exports