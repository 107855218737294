<template>
  <div class="loading-screen">
    <div class="content">
      <div class="indicator-wrapper  mb-s">
        <div class="loading-indicator  -outline">
          <EvoOutlineSVG></EvoOutlineSVG>
        </div>
        <div class="loading-indicator  -full">
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
               viewBox="0 0 101.5 205.2" enable-background="new 0 0 101.5 205.2">
          <!-- eslint-disable -->
<path mask="url(#testMask)" fill="#959DA5" d="M70.4,91c9.7-2.4,15.6-12.3,13.2-22s-12.3-15.6-22-13.2c-6.5,1.6-11.5,6.7-13.2,13.1
	C45.9,61.8,38.2,58,31,60.5c-7.1,2.5-10.9,10.2-8.4,17.4c1.4,4,4.5,7.1,8.5,8.5c-9.7,2.4-15.7,12.3-13.2,22s12.3,15.7,22,13.2
	c6.5-1.6,11.5-6.7,13.2-13.1c2.5,7.1,10.2,10.9,17.4,8.5s10.9-10.2,8.5-17.4C77.6,95.5,74.4,92.4,70.4,91L70.4,91z"/>
            <ellipse mask="url(#testMask)" fill="#D60414" cx="35.5" cy="103.9" rx="10.5" ry="10.5"/>
            <ellipse mask="url(#testMask)" fill="#D60414" cx="66" cy="103.9" rx="7.3" ry="7.3"/>
            <ellipse mask="url(#testMask)" fill="#0094D8" cx="35.5" cy="73.1" rx="7.3" ry="7.3"/>
            <ellipse mask="url(#testMask)" fill="#0094D8" cx="66" cy="73.1" rx="10.5" ry="10.5"/>
            <path mask="url(#testMask)" fill="#959DA5" d="M58.9,22.5c4.8-4.6,5-12.1,0.5-16.9s-12.2-5.1-17-0.5s-5,12.1-0.5,16.9c0.1,0.2,0.3,0.3,0.5,0.5
	c-9.8,0.3-19.4,10-19.4,10s8.8,6.2,27.9,6.2s27.4-6.2,27.4-6.2S69.4,22.8,58.9,22.5z M45.5,12.4c0.8-2.9,3.8-4.5,6.6-3.7
	c2.9,0.8,4.5,3.8,3.7,6.6C55,18.2,52,19.8,49.2,19C46.4,18.2,44.7,15.2,45.5,12.4z"/>
            <path mask="url(#testMask)" fill="#959DA5" d="M35.2,54.6c4.4,0,8.7,1.5,12.1,4.4c7.7-10,21.8-12.3,32.3-5.2V37c0,0-11.4,6.2-28.8,6.2s-29-6.2-29-6.2v23.2
	C25.4,56.6,30.2,54.6,35.2,54.6z"/>
            <path mask="url(#testMask)" fill="#959DA5" d="M84.3,128.6v3.8c7.5,2.5,9.9,5.1,9.9,5.1H79.6v-20.4c-6.9,7-18,7.5-25.5,1.2c-7.6,10-21.8,12.3-32.2,5.3
	v13.9H7.4c0,0,2.4-2.6,9.9-5.1v-3.8c-11,2.8-15.9,7.1-15.9,11v8.1c0.2,1.9,1.8,3.3,3.7,3.2h16.8V203h57.7v-52.1h16.9
	c1.9,0.1,3.5-1.3,3.7-3.2v-8.1C100.2,135.7,95.3,131.4,84.3,128.6z"/>
            <polygon mask="url(#testMask)" fill="#444D56" points="84.3,181.2 17.3,194.1 17.3,187 84.3,174.2 "/>
            <polygon mask="url(#testMask)" fill="#444D56" points="84.3,190.7 17.3,203.4 84.3,203.4 "/>
            <polygon mask="url(#testMask)" fill="#444D56" points="17.3,163.7 84.3,150.9 17.3,150.9 "/>
            <polygon mask="url(#testMask)" fill="#444D56" points="17.3,179.7 84.2,166.8 84.2,159.8 17.3,172.6 "/>

            <mask id="testMask">
              <rect x="0" y="0" width="100%" height="100%" fill="white"></rect>
              <rect id="revealMask" x="0" y="0" width="101.5"
                    :height="revealHeight" fill="black"></rect>
            </mask>
            <!-- eslint-enable -->
          </svg>
        </div>
      </div>
      <div class="text  text-center">
        <div class="h3  mb-s">{{ $t('loading.title') }}</div>
        <div class="h3  color-brand-blue">{{ percent }} %</div>
      </div>
    </div>
  </div>
</template>

<script>
// import EvoFullSVG from '@/assets/loading/evo-full.svg';
import EvoOutlineSVG from '@/assets/loading/evo-outline.svg';

export default {
  name: 'LoadingScreen',
  data() {
    return {
      percent: 0,
      timeOrigin: 2000,
      resourcesLoaded: false,
    };
  },
  computed: {
    revealHeight() {
      return (Math.abs(this.percent - 100) / 100) * 205.2;
    },
  },
  mounted() {
    this.timeOrigin = parseInt((window.performance.timeOrigin / 1000) % 60, 10) * 100 || 2000;
    console.log('set time origin', this.timeOrigin);

    setTimeout(() => {
      window.requestAnimationFrame(this.fillPercent);
    }, 2000);

    window.addEventListener('load', this.handleLoad.bind(this));
  },
  methods: {
    fillPercent() {
      const log = Math.abs((this.percent - 100)) * 0.25;
      const random = Math.floor(Math.random() * (log - 2) + 2);

      if (random % 2 === 0 || random < 3) {
        if (this.percent + random > 99) {
          this.percent = 100;
        } else {
          this.percent += random;
        }
      }

      if (this.percent < 99) {
        setTimeout(() => {
          window.requestAnimationFrame(this.fillPercent);
        }, this.timeOrigin / 10);
      } else if (this.resourcesLoaded) {
        this.percent = 100;

        setTimeout(() => {
          this.showPage();
        }, 400);
      }
    },

    showPage() {
      this.$el.classList.add('-is-finished');
      this.$emit('finishedLoading');
    },

    handleLoad() {
      this.resourcesLoaded = true;
      this.timeOrigin = 500;
      console.log('set loaded', this.resourcesLoaded, this.timeOrigin);
      if (this.percent >= 99) {
        setTimeout(() => {
          this.showPage();
        }, 400);
      }
    },
  },
  components: {
    EvoOutlineSVG,
    // EvoFullSVG,
  },
};
</script>

<style scoped lang="scss" src="@/sass/08_modules/loading-screen.scss"></style>
