<template>
  <div class="content-overlay" :class="{ ' -is-active': show }">
    <div class="overlay" @click="toggle()"></div>
    <div class="content">
      <Button class="close  -yellow" @click.native="toggle()">
        {{ $t('conversion.buttons.details.collapse') }}
        <Icon class="-inline  -is-right"><CollapseSVG></CollapseSVG></Icon>
      </Button>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import Button from '@/components/atoms/Button.vue';
import Icon from '@/components/atoms/Icon.vue';

import CollapseSVG from '@/assets/icons/arrow-collapse.svg';

export default {
  name: 'ContentOverlay',
  data() {
    return {
      show: false,
    };
  },
  methods: {
    toggle() {
      this.show = !this.show;
    },
  },
  components: {
    Button,
    Icon,
    CollapseSVG,
  },
};
</script>

<style scoped lang="scss" src="@/sass/07_elements/content-overlay.scss"></style>
