<template>
  <section class="conversion-teaser" id="contact">
    <div class="site-inner">
      <transition-group name="fade" mode="out-in">
        <div class="profile-content" key="motorsport" v-view>
          <div class="row  mb-m  text-center">
            <div class="col-12">
              <h3 class="h3  mb-m">{{ $t('conversion.profile_1.products.headline') }}</h3>
            </div>
            <div class="col-12  pr-sm-0  pl-sm-0">
              <nav class="tab-nav">
                <Tabswitch v-for="(tab, index) in $t('conversion.profile_1.products.tabs')"
                           :key="index"
                           :class="{ '-is-active': activeTab.motorsport === index }"
                           @click.native="tabSwitchHandler('motorsport', index, `Motorsport Tab ${index+1}`)">
                  {{ tab.name }}
                </Tabswitch>
              </nav>
            </div>
          </div>
          <div class="row  mb-xl">
            <transition-group name="fade" mode="out-in">
              <div class="tab-content"
                   v-for="(tab, index) in $t('conversion.profile_1.products.tabs')"
                   :key="`key-${index}`"
                   v-show="activeTab.motorsport === index"
                   :class="{ '-is-active': activeTab.motorsport === index }">
                <div v-if="tab.product_keys.length > 4 || $mq.sm" class="pos-relative">
                  <TinySlider v-bind="tinySliderOptions" class="display-flex"
                              :controls-container="`#p1-tns-controls-${index}`">
                    <div class="col-sm-6  col-xs-12  pl-0  pr-0"
                         :class="{
                        ' col-4': tab.product_keys.length <= 3,
                        ' col-3': tab.product_keys.length > 3
                       }"
                         v-for="(product, index) in tab.product_keys" :key="index">
                      <ProductTeaser :product_key="product" :bus="bus"></ProductTeaser>
                    </div>
                  </TinySlider>
                  <div class="tns-control-container" :id="`p1-tns-controls-${index}`">
                    <div class="tns-control  tns-prev-arrow">
                      <Icon class="-inline  -size-20  -rotate-90"><ArrowSVG></ArrowSVG></Icon>
                    </div>
                    <div class="tns-control  tns-next-arrow">
                      <Icon class="-inline  -size-20  -rotate-270"><ArrowSVG></ArrowSVG></Icon>
                    </div>
                  </div>
                </div>
                <div v-if="tab.product_keys.length <= 4 && !$mq.sm" class="display-flex">
                  <div class="col-sm-6  col-xs-12"
                       :class="{
                       ' col-4': tab.product_keys.length <= 3,
                       ' col-3': tab.product_keys.length > 3
                     }"
                       v-for="(product, index) in tab.product_keys" :key="index">
                    <ProductTeaser :product_key="product" :bus="bus"></ProductTeaser>
                  </div>
                </div>
              </div>
            </transition-group>
          </div>
          <div class="row">
            <div class="col-8  col-sm-12">
              <h5 class="h5">{{ $t('conversion.form.headline') }}</h5>
              <p>{{ $t('conversion.form.paragraph') }}</p>
            </div>
          </div>
          <ContactForm :products="motorsportActiveTabProducts"></ContactForm>
        </div>
      </transition-group>
    </div>
    <ProductDetails :bus="bus"></ProductDetails>
  </section>
</template>

<script>
import Vue from 'vue';
import smoothReflow from 'vue-smooth-reflow';

import Icon from '@/components/atoms/Icon.vue';
import Tabswitch from '@/components/atoms/Tabswitch.vue';
import RatioBox from '@/components/elements/RatioBox.vue';
import ProductTeaser from '@/components/elements/ProductTeaser.vue';
import ProductDetails from '@/components/elements/ProductDetails.vue';
import ContactForm from '@/components/modules/ContactForm.vue';

import TinySlider from 'vue-tiny-slider';
import ArrowSVG from '@/assets/icons/arrow.svg';

export default {
  name: 'ConversionTeaser',
  mixins: [smoothReflow],
  data() {
    return {
      bus: new Vue(),
      isActive: false,
      isTuner: false,
      activeTab: {
        motorsport: 0,
        tuner: 0,
      },
      tinySliderOptions: {
        items: 1,
        loop: false,
        gutter: 16,
        mouseDrag: true,
        responsive: {
          600: {
            items: 2,
          },
          1200: {
            items: 4,
          },
        },
      },
    };
  },
  mounted() {
    this.$smoothReflow(this.$refs.smoothHeight);
  },
  computed: {
    motorsportClasses() {
      return {
        ' col-6': !this.isActive,
        ' col-3  -is-inactive': this.isActive && this.isTuner,
        ' col-9  -is-active': this.isActive && !this.isTuner,
      };
    },

    tunerClasses() {
      return {
        ' col-6': !this.isActive,
        ' col-3  -is-inactive': this.isActive && !this.isTuner,
        ' col-9  -is-active': this.isActive && this.isTuner,
      };
    },

    motorsportActiveTabProducts() {
      return this.$t(`conversion.profile_1.products.tabs[${this.activeTab.motorsport}].product_keys`);
    },
  },
  methods: {
    toggleSelection(isTuner) {
      const profileName = (isTuner) ? 'Tuner' : 'Motorsport';
      if (!this.isActive) {
        this.isActive = true;
        this.isTuner = isTuner;

        this.sendEvent(`Profile select ${profileName}`);
      } else if (this.isActive) {
        if (this.isTuner === isTuner) {
          this.isActive = false;
        } else {
          this.isTuner = isTuner;

          this.sendEvent(`Profile change ${profileName}`);
        }
      }
    },

    tabSwitchHandler(target, index, label) {
      this.activeTab[target] = index;
      this.sendEvent(label);
    },

    sendEvent(label) {
      if (typeof gtag !== 'undefined') {
        gtag('event', 'click', {
          event_category: 'Conversion Teaser',
          event_label: label,
        });
      }
    },
  },
  components: {
    Icon,
    Tabswitch,
    RatioBox,
    ProductTeaser,
    ProductDetails,
    TinySlider,
    ContactForm,
    ArrowSVG,
  },
};
</script>

<style scoped lang="scss" src="@/sass/08_modules/conversion-teaser.scss"></style>
