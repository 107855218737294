import { render, staticRenderFns } from "./SlideFade.vue?vue&type=template&id=7e1051e3&scoped=true&"
import script from "./SlideFade.vue?vue&type=script&lang=js&"
export * from "./SlideFade.vue?vue&type=script&lang=js&"
import style0 from "@/sass/07_elements/slidefade-transition.scss?vue&type=style&index=0&id=7e1051e3&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e1051e3",
  null
  
)

export default component.exports